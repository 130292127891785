
import { FeatureFlags } from 'lib-customizations/types'

import { env, Env } from './env'

type Features = {
  default: FeatureFlags
} & {
  [k in Env]: FeatureFlags
}

const features: Features = {
  default: {
    citizenShiftCareAbsence: true,
    assistanceActionOther: true,
    daycareApplication: {
      dailyTimes: true,
    },
    preschoolApplication: {
      connectedDaycarePreferredStartDate: true,
      serviceNeedOption: true,
    },
    decisionDraftMultipleUnits: true,
    preschool: true,
    preparatory: false,
    urgencyAttachments: true,
    financeDecisionHandlerSelect: true,
    feeDecisionPreschoolClubFilter: true,
    placementGuarantee: false,
    voucherUnitPayments: true,
    assistanceNeedDecisionsLanguageSelect: true,
    staffAttendanceTypes: true,
    extendedPreschoolTerm: true,
    personDuplicate: true,
    citizenAttendanceSummary: true,
    intermittentShiftCare: true,
    noAbsenceType: true,
    timeUsageInfo: true,
  },
  staging: {
    citizenShiftCareAbsence: true,
    assistanceActionOther: false,
    daycareApplication: {
      dailyTimes: true,
    },
    preschoolApplication: {
      connectedDaycarePreferredStartDate: false,
      serviceNeedOption: false,
    },
    decisionDraftMultipleUnits: false,
    preschool: false,
    preparatory: false,
    urgencyAttachments: false,
    financeDecisionHandlerSelect: false,
    feeDecisionPreschoolClubFilter: false,
    placementGuarantee: true,
    voucherUnitPayments: false,
    assistanceNeedDecisionsLanguageSelect: false,
    staffAttendanceTypes: false,
    extendedPreschoolTerm: false,
    personDuplicate: false,
    citizenAttendanceSummary: true,
    intermittentShiftCare: false,
    noAbsenceType: true,
    timeUsageInfo: false,
  },
  prod: {
    citizenShiftCareAbsence: true,
    assistanceActionOther: false,
    daycareApplication: {
      dailyTimes: true,
    },
    preschoolApplication: {
      connectedDaycarePreferredStartDate: false,
      serviceNeedOption: false,
    },
    decisionDraftMultipleUnits: false,
    preschool: false,
    preparatory: false,
    urgencyAttachments: false,
    financeDecisionHandlerSelect: false,
    feeDecisionPreschoolClubFilter: false,
    placementGuarantee: true,
    voucherUnitPayments: false,
    assistanceNeedDecisionsLanguageSelect: false,
    staffAttendanceTypes: false,
    extendedPreschoolTerm: false,
    personDuplicate: false,
    citizenAttendanceSummary: true,
    intermittentShiftCare: false,
    noAbsenceType: true,
    timeUsageInfo: false,
  },
}

const featureFlags = features[env()]

export default featureFlags
