
import { MapConfig } from 'lib-customizations/types'

const mapConfig: MapConfig = {
  center: [64.88475, 28.90968],
  initialZoom: 13,
  addressZoom: 14,
  searchAreaRect: {
    maxLatitude: 65.1147509641538,
    minLatitude: 64.68480885847302,
    maxLongitude: 29.209685029760976,
    minLongitude: 28.709685029760976,
  },
  careTypeFilters: ['DAYCARE', 'PRESCHOOL', 'CLUB'],
  unitProviderTypeFilters: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'PRIVATE_SERVICE_VOUCHER',
  ],
}
export default mapConfig
