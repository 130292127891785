// SPDX-FileCopyrightText: 2017-2022 City of Espoo
//
// SPDX-License-Identifier: LGPL-2.1-or-later

import React from 'react'

import {
  daycareAssistanceLevels,
  preschoolAssistanceLevels
} from 'lib-common/generated/api-types/assistance'
import type { EmployeeCustomizations } from 'lib-customizations/types'
import { H3, P } from 'lib-components/typography'
import { employeeConfig } from './appConfigs'
import MunicipalityLogo from './assets/municipality-logo-primary.svg'
import featureFlags from './featureFlags'

const customizations: EmployeeCustomizations = {
  appConfig: employeeConfig,
  translations: {
    fi: {
      common: {
        retroactiveConfirmation: {
          checkboxLabel:
            'Ymmärrän, olen asiasta yhteydessä laskutustiimiin vaka.maksut@suomussalmi.fi *'
        }
      },
      footer: {
        cityLabel: 'Suomussalmen kunta',
        linkLabel: 'Suomussalmen varhaiskasvatus',
        linkHref: 'https://www.suomussalmi.fi/fi/varhaiskasvatus-ja-koulutus/varhaiskasvatus'
      },
      unitEditor: {
        placeholder: {
          email: 'etunimi.sukunimi@suomussalmi.fi',
          url: 'esim. https://www.suomussalmi.fi/fi/toimipisteet/15585',
        }
      },
      welcomePage: {
        text: 'Olet kirjautunut sisään Suomussalmen kunnan eVaka-palveluun. Käyttäjätunnuksellesi ei ole vielä annettu oikeuksia, jotka mahdollistavat palvelun käytön. Tarvittavat käyttöoikeudet saat omalta esimieheltäsi.'
      },
      preferredFirstName: {
        description:
      'Voit määritellä eVakassa käytössä olevan kutsumanimesi. Kutsumanimen tulee olla jokin etunimistäsi. Jos nimesi on vaihtunut ja sinulla on tarve päivittää eVakaan uusi nimesi, ole yhteydessä Suomussalmen tukeen.',
      },
      login: {
        loginAD: 'Suomussalmen AD',
      },
      childInformation: {
        assistanceNeedPreschoolDecision: {
          appealInstructions: (
            <>
              <H3>Oikaisuvaatimusoikeus</H3>
              <P>
                Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai
                jonka oikeuteen, velvollisuuteen tai etuun päätös välittömästi
                vaikuttaa (asianosainen).
              </P>
              <H3>Oikaisuvaatimusaika</H3>
              <P>
                Oikaisuvaatimus on tehtävä 30 päivän kuluessa päätöksen
                tiedoksisaannista.
              </P>
              <H3>Tiedoksisaanti</H3>
              <P>
                Kirjeen lähettämisestä seitsemän päivän kuluttua / Saantitodistukseen tai
                tiedoksiantotodistukseen merkittynä päivänä / Sähköisen viestin lähettämisestä kolmantena
                päivänä. Jos määräajan viimeinen päivä on pyhäpäivä / itsenäisyyspäivä / vapunpäivä / joulu- tai
                juhannusaatto / arkilauantai, saa tehtävän toimittaa ensimmäisenä arkipäivänä sen jälkeen.
              </P>
              <H3>Oikaisuviranomainen</H3>
              <P>Oikaisu tehdään Pohjois-Suomen aluehallintovirastolle. Oikaisuvaatimuksen voi tehdä sähköisenä / postitse.</P>
              <P>
                <a href='https://www.suomi.fi/palvelut/verkkoasiointi/oikaisuvaatimus-varhaiskasvatus-opetus-ja-koulutus-aluehallintovirasto/d7022cad-9ecd-4dad-84ec-17489d260a8d'>verkkoasiointi</a>
                <br />
                Pohjois-Suomen aluehallintovirasto
                <br />
                Käyntiosoite:Linnankatu 3 90100 OULU
                <br />
                Virastoaika: ma-pe 8.00–16.15
                <br />
                Postiosoite: PL 1, 13035 AVI
                <br />
                Sähköposti: kirjaamo.pohjois @avi.fi
                <br />
                Puhelin: 0295 016 000
              </P>
              <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
              <P>
                Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
                täyttää vaatimuksen kirjallisesta muodosta.
              </P>
              <P noMargin>Oikaisuvaatimuksessa on ilmoitettava</P>
              <ul>
                <li>
                  Tekijän nimi, kotikunta, postiosoite, puhelinnumero ja muut yhteystiedot
                </li>
                <li>päätös, johon haetaan oikaisua</li>
                <li>
                  miltä osin päätökseen haetaan oikaisua ja mitä oikaisua siihen
                  vaaditaan tehtäväksi
                </li>
                <li>vaatimuksen perusteet</li>
              </ul>
              <P>
                Jos oikaisuvaatimuspäätös voidaan antaa tiedoksi sähköisenä
                viestinä, yhteystietona pyydetään ilmoittamaan myös
                sähköpostiosoite.
              </P>
              <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
              <ul>
                <li>
                  päätös, johon haetaan oikaisua, alkuperäisenä tai jäljennöksenä
                </li>
                <li>
                  todistus tiedoksisaannista tai
                  muu selvitys oikaisuvaatimusajan alkamisen ajankohdasta
                </li>
                <li>
                  asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa
                  oikaisuvaatimuksensa tueksi, jollei niitä ole jo aikaisemmin
                  toimitettu viranomaiselle.
                </li>
              </ul>
              <H3>Oikaisuvaatimuksen toimittaminen</H3>
              <P>
                Oikaisuvaatimuskirjelmä on toimitettava oikaisuvaatimusajan
                kuluessa oikaisuvaatimusviranomaiselle. Oikaisuvaatimuskirjelmän
                tulee olla perillä oikaisuvaatimusajan viimeisenä päivänä ennen
                viraston aukiolon päättymistä. Oikaisuvaatimuksen lähettäminen
                postitse tai sähköisesti tapahtuu lähettäjän omalla vastuulla.
              </P>
            </>
          ),
        },
        assistanceNeedDecision: {
          appealInstructions: (
            <>
              <H3>Oikaisuvaatimusoikeus</H3>
              <P>
                Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai
                jonka oikeuteen, velvollisuuteen tai etuun päätös välittömästi
                vaikuttaa (asianosainen).
              </P>
              <H3>Oikaisuvaatimusaika</H3>
              <P>
                Oikaisuvaatimus on tehtävä 30 päivän kuluessa päätöksen
                tiedoksisaannista.
              </P>
              <H3>Tiedoksisaanti</H3>
              <P>
                Kirjeen lähettämisestä seitsemän päivän kuluttua / Saantitodistukseen tai
                tiedoksiantotodistukseen merkittynä päivänä / Sähköisen viestin lähettämisestä kolmantena
                päivänä. Jos määräajan viimeinen päivä on pyhäpäivä / itsenäisyyspäivä / vapunpäivä / joulu- tai
                juhannusaatto / arkilauantai, saa tehtävän toimittaa ensimmäisenä arkipäivänä sen jälkeen.
              </P>
              <H3>Oikaisuviranomainen</H3>
              <P>Oikaisu tehdään Pohjois-Suomen aluehallintovirastolle. Oikaisuvaatimuksen voi tehdä sähköisenä / postitse.</P>
              <P>
                <a href='https://www.suomi.fi/palvelut/verkkoasiointi/oikaisuvaatimus-varhaiskasvatus-opetus-ja-koulutus-aluehallintovirasto/d7022cad-9ecd-4dad-84ec-17489d260a8d'>verkkoasiointi</a>
                <br />
                Pohjois-Suomen aluehallintovirasto
                <br />
                Käyntiosoite:Linnankatu 3 90100 OULU
                <br />
                Virastoaika: ma-pe 8.00–16.15
                <br />
                Postiosoite: PL 1, 13035 AVI
                <br />
                Sähköposti: kirjaamo.pohjois @avi.fi
                <br />
                Puhelin: 0295 016 000
              </P>
              <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
              <P>
                Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
                täyttää vaatimuksen kirjallisesta muodosta.
              </P>
              <P noMargin>Oikaisuvaatimuksessa on ilmoitettava</P>
              <ul>
                <li>
                  Tekijän nimi, kotikunta, postiosoite, puhelinnumero ja muut yhteystiedot
                </li>
                <li>päätös, johon haetaan oikaisua</li>
                <li>
                  miltä osin päätökseen haetaan oikaisua ja mitä oikaisua siihen
                  vaaditaan tehtäväksi
                </li>
                <li>vaatimuksen perusteet</li>
              </ul>
              <P>
                Jos oikaisuvaatimuspäätös voidaan antaa tiedoksi sähköisenä
                viestinä, yhteystietona pyydetään ilmoittamaan myös
                sähköpostiosoite.
              </P>
              <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
              <ul>
                <li>
                  päätös, johon haetaan oikaisua, alkuperäisenä tai jäljennöksenä
                </li>
                <li>
                  todistus tiedoksisaannista tai
                  muu selvitys oikaisuvaatimusajan alkamisen ajankohdasta
                </li>
                <li>
                  asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa
                  oikaisuvaatimuksensa tueksi, jollei niitä ole jo aikaisemmin
                  toimitettu viranomaiselle.
                </li>
              </ul>
              <H3>Oikaisuvaatimuksen toimittaminen</H3>
              <P>
                Oikaisuvaatimuskirjelmä on toimitettava oikaisuvaatimusajan
                kuluessa oikaisuvaatimusviranomaiselle. Oikaisuvaatimuskirjelmän
                tulee olla perillä oikaisuvaatimusajan viimeisenä päivänä ennen
                viraston aukiolon päättymistä. Oikaisuvaatimuksen lähettäminen
                postitse tai sähköisesti tapahtuu lähettäjän omalla vastuulla.
              </P>
            </>
          )
        }
      },
    },
    sv: {},
  },
  vasuTranslations: {
    FI: {},
    SV: {},
  },
  cityLogo: <img src={MunicipalityLogo} alt="Suomussalmi logo" data-qa="footer-city-logo" />,
  featureFlags,
  placementTypes: [
    'PRESCHOOL',
    'PRESCHOOL_DAYCARE',
    'DAYCARE',
    'DAYCARE_PART_TIME',
    'PREPARATORY',
    'PREPARATORY_DAYCARE',
    'CLUB',
    'TEMPORARY_DAYCARE',
    'TEMPORARY_DAYCARE_PART_DAY',
  ],
  absenceTypes: [
    'OTHER_ABSENCE',
    'SICKLEAVE',
    'UNKNOWN_ABSENCE',
    'PLANNED_ABSENCE',
    'PARENTLEAVE',
    'FORCE_MAJEURE',
  ],
  daycareAssistanceLevels: [...daycareAssistanceLevels],
  otherAssistanceMeasureTypes: [
    'TRANSPORT_BENEFIT',
    'ACCULTURATION_SUPPORT',
    'ANOMALOUS_EDUCATION_START',
  ],
  placementPlanRejectReasons: ['REASON_1', 'REASON_2', 'OTHER'],
  preschoolAssistanceLevels: [...preschoolAssistanceLevels],
  unitProviderTypes: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'MUNICIPAL_SCHOOL',
    'PRIVATE_SERVICE_VOUCHER',
    'EXTERNAL_PURCHASED',
  ],
  voucherValueDecisionTypes: [
    'NORMAL',
    'RELIEF_ACCEPTED',
    'RELIEF_PARTLY_ACCEPTED',
    'RELIEF_REJECTED',
  ],
}

export default customizations
